/* eslint-disable */
import React, { Children, useEffect } from "react";
import { useState } from 'react';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { useHover } from "react-use";

// css
import styles from "../css/sub2.module.css";

//import component
import Header from "../common/Header";
import Footer from "../common/Footer";
import Sub2_1 from "./Subpage_2_1";
import Sub2_2 from "./Subpage_2_2";


function Sub2() {

  //tab
  let [tab, setTab] = useState(0);

  return (
    <>
      <div>
        {/* header */}
        <Header/>

        <section className={`${styles.section} ${styles.section1}`}>
          <div className={styles.inner}>
            <h3 className={styles.w_view}>유전체 분석 제품</h3>
            
            <div className={styles.tab_box}>
              <div onClick={() => { setTab(0) }} className={ tab == 0 ? `${styles.active}` : ''}>제품 라인업</div>
              <div onClick={() => { setTab(1) }} className={ tab == 1 ? `${styles.active}` : ''}>제품 이용방법</div>
            </div>

            {/* 1 */}
            { tab == 0 ? <Sub2_1/> : null }
            {/* 2 */}
            { tab == 1 ? <Sub2_2/> : null }




          </div>
        </section>

        {/* footer */}
        <Footer/>
      </div>
    </>
  );
}

export default Sub2;
