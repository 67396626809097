/* eslint-disable */
import React, { Children, useEffect, useState } from "react";
import styled from "styled-components";

// css
import styles from "../css/sub2.module.css";

function Sub2_2() {

  return (
    <>
      <div>
        <div className={styles.sub2_2_wrap}>
          <div className={styles.content1}>
            <h3>한컴GX 이용하기</h3>

            <p className={styles.w_view}>이용 프로세스</p>
            <div>
              <ul className={styles.clearfix}>
                <li className={styles.slider1}>
                  <p>온라인에서<br/>DTC 상품 구매</p>
                </li>
                <li className={styles.slider2}>
                  <p>DNA 채취<br/>키트 발송</p>
                </li>
                <li className={styles.slider3}>
                  <p>DNA 키트<br/>수령</p>
                </li>
                <li className={styles.slider4}>
                  <p>분석 샘플 준비<br/>구강상피세포 채취</p>
                </li>
                <li className={styles.slider5}>
                  <p>DNA 키트<br/>반송</p>
                </li>
                <li className={styles.slider6}>
                  <p>DNA<br/>분석</p>
                </li>
                <li className={styles.slider7}>
                  <p>온라인에서<br/>분석 결과 확인</p>
                </li>
              </ul>
            </div>
          </div>

          <div className={styles.content2}>
            <h3>한컴GX DNA 키트 사용방법</h3>

            <p className={styles.w_view}>DNA 키트 구성</p>
            <div>
              <ul>
                <li>면봉(긴 튜브)</li>
                <li>보존액 튜브</li>
                <li>바코드 2개</li>
                <li>유전자 검사 동의서</li>
                <li>사용 설명서</li>
                <li>실링 스티커</li>
              </ul>
            </div>
          </div>

          <div className={styles.etc_box}>
            <p>검체 채취 전 주의사항</p>
            <ul>
              <li>구강세포는 아침 기상 후 가장 많으므로 검체 (구강점막세포) <b>채취는 아침 기상 후</b>에 하는 것을 권장 합니다.</li>
              <li>검체 채취 1시간 전에는 <b>양치, 가글, 음식·음료 및 물 섭취, 껌, 담배 등</b>을 반드시 삼가 주세요.</li>
              <li>충분한 검체가 확보되지 않을 경우 정확한 분석 결과를 제공할 수 없으니, <b>주의사항을 꼭 지켜서 검체를 채취해 주세요!</b> 😆</li>
            </ul>
          </div>

          <div className={styles.content4}>
            <p>검체 채취 방법</p>
            <div>
              <ul>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
            <div>
              <ul>
                <li>튜브의 뚜껑을 돌려서 엽니다. 이때 면봉을 손으로 만지지 않게 조심합니다.</li>
                <li>면봉을 양치 하듯이 양볼, 잇몸, 혀밑을 1분 이상 구석구석 문질러 구상 상피세포와 타액이 잘 수집 될 수 있도록 합니다. (면봉에 타액이 흠뻑 적셔질 수 있도록 함.)</li>
                <li>면봉을 보존액이 들어있는 튜브에 넣고 홈이 있는 부분을 튜브의 끝에 대고 막대기를 분질러 면봉만 튜브에 담습니다.</li>
                <li>뚜껑을 완전히 잠근 후 튜브를 10회 정도 흔들어 누수가 없는지 확인합니다.</li>
                <li>채취가 끝난 튜브에 바코드를 붙이고, 작성하신 유전자 검사 동의서를 함께 키트 박스에 동봉 합니다.</li>
                <li>실링 스티커로 키트 박스를 봉인한 후에 우체국 등기나 택배를 통해 키트 박스를 보내주세요.</li>
              </ul>
            </div>
          </div>

          <div className={styles.etc_box}>
            <p>검체 채취 후</p>
            <ul>
              <li>유전체 분석 동의서 작성 시 동의서 상단에 <b>필수항목 (성명, 생년월일, 주소, 이메일 주소, 연락처, 성별)</b>을 반드시 작성한 후, 하단에 성명 및 서명을 합니다.</li>
              <li>키트박스에 동봉된 바코드 1장을 <b>동의서 상단(바코드 부착영역)</b>에 붙이고 나머지 바코드 1장은 <b>면봉을 넣은 보존액 튜브</b>에 붙입니다.</li>
              <li>검체를 채취한 면봉이 넣어진 보존액 튜브의 뚜껑이 잘 닫혔는지, 유전체 분석 동의서가 정확하게 작성되었는지 확인한 후 <b>키트박스에 이 둘을 동봉</b>하여 <br className={styles.not_mt_view}/><b>한컴GX 유전체 분석센터</b>로 보내주세요.</li>
            </ul>
          </div>

        </div>
      </div>
    </>
  );
}

export default Sub2_2;
