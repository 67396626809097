/* eslint-disable */
/* global kakao */
import React, { Children, useRef, useEffect } from "react";
import { useState } from 'react';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import styled from "styled-components";
import cn from "classnames";

// css
import styles from "../css/sub3~etc.module.css";

//import component
import Header from "../common/Header";
import Footer from "../common/Footer";

const kakao = (window as any).kakao;

function Sub3() {

  useEffect(() => {

    const container = document.getElementById('map');

    const options = {
      center: new kakao.maps.LatLng(37.400617713764206,127.11239797585472),
      level: 3,
    };

    const map = new kakao.maps.Map(container, options);

    //마커가 표시 될 위치
    const markerPosition = new kakao.maps.LatLng(
      37.400617713764206,
      127.11239797585472
    );

    // 마커를 생성
    const marker = new kakao.maps.Marker({
      position: markerPosition,
    });

    // 마커를 지도 위에 표시
    marker.setMap(map);

  }, []);

  return (
    <>
      <div>
        {/* header */}
        <Header/>

        <section className={`${styles.section} ${styles.location}`}>
          <div className={styles.inner}>
            <h3>검사기관 위치</h3>

            <div className={styles.location_wrap}>
              <div className={styles.map_wrap}>
                <div className={cn("Map")}>
                  <div className={cn("MapContainer")} id="map" 
                    style={{width: "100%", aspectRatio: "1 / 1", maxHeight: "488px" }}
                  ></div>
                </div>
              </div>

              <div className={styles.table}>
                <table>
                  <tbody>
                    <tr>
                      <th>회사명</th>
                      <td>한컴케어링크</td>
                    </tr>
                    <tr>
                      <th>대표이사</th>
                      <td>천창기</td>
                    </tr>
                    <tr>
                      <th>주소</th>
                      <td>경기도 성남시 분당구 대왕판교로 644번길 49 한컴타워 5층</td>
                    </tr>
                    <tr>
                      <th>전화</th>
                      <td>02-1800-5270</td>
                    </tr>
                    <tr>
                      <th>유전자 검사기관 신고번호</th>
                      <td>제404호</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </section>
        {/* footer */}
        <Footer/>
      </div>
    </>
  );
}

export default Sub3;
