/* eslint-disable */
import React, { Children, LiHTMLAttributes } from "react";

// css
import styles from "../css/sub1.module.css";

//import component
import Header from "../common/Header";
import Footer from "../common/Footer";
import UseScrollFade_left from "../common/UseScrollFade/UseScrollFade_left";

function Sub1() {
  type LiProps = LiHTMLAttributes<HTMLLIElement>;
  
  const fade_one = Array.from({ length: 4 }, () => UseScrollFade_left());
  
  return (
    <>
      <div className={styles.wrap}>
        {/* header */}
        <Header/>
        
        <section className={`${styles.section} ${styles.section1}`}>
          <div className={styles.inner}>
            <h3>한컴GX 소개</h3>
            <dl className={styles.intro}>
              <dt>새로운 나를 만날 시간, <span>한컴GX</span></dt>
              <dd>한컴GX는 한컴케어링크에서 제공하는 <br className={styles.m_view}/><b>유전체 분석 서비스</b> 입니다.</dd>
              <dd><span>G</span>는 유전체를 의미하는 <br className={styles.m_view}/><span>Genome의 첫 글자</span>이며, <br/><span>X</span>는<span>유전체의 모양</span>을 형상화하여 <br className={styles.m_view}/><span>다양한 유전체 분석 서비스</span>들을 <br className={styles.m_view}/>제공함을 의미합니다.</dd>
            </dl>  
            <div className={styles.report}>
              <ul>
                <li {...fade_one[1] as LiProps}></li>
                <li {...fade_one[2] as LiProps}></li>
                <li {...fade_one[3] as LiProps}></li>
              </ul>
            </div> 
          </div>
          <div className={styles.report_txt}>
            <dl>
              <dt>유전체 분석 검사 리포트 제공</dt>
              <dd>유전체 검사 요약 결과,검체별 종합 결과,<br className={styles.w_view}/>
              관리 방법 등을 <br className={styles.m_view}/>
              <span>개인별 종합 결과 리포트</span>로 <br className={styles.w_view}/>
              제공해 드립니다.</dd>
            </dl>
          </div>       
        </section>

        <section className={`${styles.section} ${styles.section2}`} id="story"></section>

        <section className={`${styles.section} ${styles.section3}`}>
          <div className={styles.inner}>
            <div className={styles.inner_wrap}>
              <div>
                <p>나의 유전체를 이해하고,<br/>내일을 계획해 보세요!</p>
              </div>
              <div>
                <p>나에게 부족한<br/>영양성분은 뭘까?</p>
              </div>
              <div>
                <p>어떻게 운동해야<br/>
                근육량이<br/>
                늘어날 수 있을까?</p>
              </div>
              <div>
                <p>좋은 피부는<br/>
                정말 타고나는 걸까?<br/> 
                나의 탈모 확률은?</p>
              </div>
              <div>
                <p>나는 아침형 인간일까?<br/>
                저녁형 인간일까?</p>
              </div>
              <div>
                <p>나에게 딱 맞는<br/> 
                다이어트 방법은 뭘까?</p>
              </div>
              <div>
                <p>나는 맛과 냄새에<br/> 
                얼마나 민감할까?</p>
              </div>
            </div>
            <p className={styles.txt}>한컴케어링크의 개인유전체분석서비스(한컴Gx)는 현재의 질병 유무를 진단하는 것이 아니라, 질병 발생에 대한 유전적 위험도를 분석하여 건강관리에 도움을 드리는 헬스케어 서비스입니다.</p>
          </div>
        </section>

        {/* footer */}
        <Footer/>
      </div>
    </>
  );
}

export default Sub1;
