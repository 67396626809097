/* eslint-disable */
import React, { Children, useRef, useEffect } from "react";

// css
import styles from "../css/sub3~etc.module.css";

//import component
import Header from "../common/Header";
import Footer from "../common/Footer";


function Inquiry() {  
  
  const iframeRef = useRef(null);

  function handleIframeLoad() {
    const iframe = iframeRef.current;
    const notNull: any = iframe!;
    const iframeHeight = notNull.contentWindow.document.body.scrollHeight;
    notNull.style.height = `${iframeHeight}px`;

    
  }

  useEffect(() => {
    function handleIframeMessage(event: any) {
      const { data: { height } } = event;
      const iframe = iframeRef.current;
      const notNull: any = iframe!;
      notNull.style.height = `${height}px`;
    }

    window.addEventListener('message', handleIframeMessage);
    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, []);

  return (
    <>
      <div>
        {/* header */}
        <Header/>

        <section className={styles.iframe_page}>
          <iframe className={`${styles.contact} ${styles.contact2}`}
              title="Inline Frame Example"
              src="https://www.hcarelink.co.kr/front/html/contact/inquiry_forDTC.jsp"
              ref={iframeRef}
              onLoad={handleIframeLoad}
              
          />
        </section>


        {/* footer */}
        <Footer/>
        
      </div>
    </>
  );
}

export default Inquiry;
