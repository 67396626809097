/* eslint-disable */
import React, { Children } from "react";
import { useState } from 'react';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import styled from "styled-components";

// css
import styles from "../css/sub3~etc.module.css";


//import component
import Footer from "../common/Footer";
import Header from "../common/Header";


const Service = () => {  
  
  const [content, setContent] = useState([false,false,false,false,false]);
  
  const handleClick = (index: any) => {
    setContent(prevContent => {
      const newContent = [...prevContent];
      newContent[index] = !newContent[index];
      return newContent;
    });
  };
  
  return (
    <>
    <div className={styles.All}>
      <ul>
        <li className={ content[0] == true ? `${styles.Que} ${styles.active}` : `${styles.Que}`} onClick={()=>{handleClick(0)}}>
          한컴 GX는?
        </li>
        { content[0] && (<li className={styles.Ans}>
          디지털헬스케어기업 ㈜한컴케어링크의 개인유전체분석서비스 브랜드명입니다. 질병 발생의 위험도 뿐만 아니라 피부, 영양소, 운동과 같은 개인 특성 등 웰니스항목의 유전적 위험도를 분석하여 건강관리에 도움을 드리는 헬스케어 서비스입니다.
        </li>)}
        <li className={ content[1] == true ? `${styles.Que} ${styles.active}` : `${styles.Que}`} onClick={()=>{handleClick(1)}}>
          건강검진과의 차이는?
        </li>
        { content[1] && (<li className={styles.Ans}>
          질병예방의 목적은 동일하지만, 건강검진은 과거와 현재의 건강상태만 확인할 수 있는 반면, 유전자검사는 미래의 질병 발생 가능성을 예측하는 검사입니다. 질병은 유전적 요인과 환경적 요인의 결합으로 발생하므로 검진 결과가 통합된다면 보다 정확한 예측이 가능할 수 있습니다. 
        </li>)}
        <li className={ content[2] == true ? `${styles.Que} ${styles.active}` : `${styles.Que}`} onClick={()=>{handleClick(2)}}>
          검사 방법은?
        </li>
        { content[2] && (<li className={styles.Ans}>
          Microarray chip(DNA chip, SNP chip, 한국인칩)은 과학기술이 집적된 고밀도 기술이자 다량의 검체 및 SNP 분석에 최적화되어 있는 기술로 (국립보건연구원국립부터 기술이전을 받아,) 이를 이용한 genotyping(유전형 분석)이라는 생물학적 실험기법을 통해 데이터를 생산하며, AI 기반의 알고리즘을 통해 분석합니다.
        </li>)}
        <li className={ content[3] == true ? `${styles.Que} ${styles.active}` : `${styles.Que}`} onClick={()=>{handleClick(3)}}>
          DTC 유전자검사란?
        </li>
        { content[3] && (<li className={styles.Ans}>
          DTC(Direct-to-Consumer) 유전자검사는 개인이 의료기관을 통하지 않고 유전자검사를 의뢰할 수 있는 유전자검사의 한 유형입니다.
        </li>)}
        <li className={ content[4] == true ? `${styles.Que} ${styles.active}` : `${styles.Que}`} onClick={()=>{handleClick(4)}}>
          DTC 유전자검사는 어떻게 하나요?
        </li>
        { content[4] && (<li className={styles.Ans}>
          ㈜한컴케어링크의 고객센터 혹은 홈페이지에서 신청할 수 있습니다. 일반적으로 타액 또는 입안에서 면봉을 이용하여 구강상피세포를 수집하여 분석기관으로 보내고, 분석완료 메시지 확인 후 사이트에서 결과를 확인할 수 있습니다. 
        </li>)}
      </ul>
    </div>    
    </>
  )
}
const Purchase = () => {
  
  const [content, setContent] = useState([false,false,false,false,false]);
  
  const handleClick = (index: any) => {
    setContent(prevContent => {
      const newContent = [...prevContent];
      newContent[index] = !newContent[index];
      return newContent;
    });
  };

  return (
    <>
    <div className={styles.All}>
      {/* <ul>
        <li className={ content[0] == true ? `${styles.Que} ${styles.active}` : `${styles.Que}`} onClick={()=>{handleClick(0)}}>
          
        </li>
        { content[0] && (<li className={styles.Ans}>
          
        </li>)}
        <li className={ content[1] == true ? `${styles.Que} ${styles.active}` : `${styles.Que}`} onClick={()=>{handleClick(1)}}>
          
        </li>
        { content[1] && (<li className={styles.Ans}>
          
        </li>)}
        <li className={ content[2] == true ? `${styles.Que} ${styles.active}` : `${styles.Que}`} onClick={()=>{handleClick(2)}}>
          
        </li>
        { content[2] && (<li className={styles.Ans}>
          
        </li>)}
        <li className={ content[3] == true ? `${styles.Que} ${styles.active}` : `${styles.Que}`} onClick={()=>{handleClick(3)}}>
          
        </li>
        { content[3] && (<li className={styles.Ans}>
          
        </li>)}
        <li className={ content[4] == true ? `${styles.Que} ${styles.active}` : `${styles.Que}`} onClick={()=>{handleClick(4)}}>
          
        </li>
        { content[4] && (<li className={styles.Ans}>
          
        </li>)}
      </ul> */}
    </div>     
    </>
  )
}
const Result = () => {
  
  const [content, setContent] = useState([false,false,false,false,false]);
  
  const handleClick = (index: any) => {
    setContent(prevContent => {
      const newContent = [...prevContent];
      newContent[index] = !newContent[index];
      return newContent;
    });
  };

  return (
    <>
    <div className={styles.All}>
      <ul>
        <li className={ content[0] == true ? `${styles.Que} ${styles.active}` : `${styles.Que}`} onClick={()=>{handleClick(0)}}>
          결과는 본인만 확인 가능한가요?
        </li>
        { content[0] && (<li className={styles.Ans}>
          유전정보는 개인정보보호법에서 규정한 민감정보로 본인의 결과지에 포함된 정보의 전부 또는 일부를 제3자에 공개, 배포하는 행위는 엄격히 금지하고 있습니다. 
        </li>)}
        {/* <li className={ content[1] == true ? `${styles.Que} ${styles.active}` : `${styles.Que}`} onClick={()=>{handleClick(1)}}>
          
        </li>
        { content[1] && (<li className={styles.Ans}>
          
        </li>)}
        <li className={ content[2] == true ? `${styles.Que} ${styles.active}` : `${styles.Que}`} onClick={()=>{handleClick(2)}}>
          
        </li>
        { content[2] && (<li className={styles.Ans}>
          
        </li>)}
        <li className={ content[3] == true ? `${styles.Que} ${styles.active}` : `${styles.Que}`} onClick={()=>{handleClick(3)}}>
          
        </li>
        { content[3] && (<li className={styles.Ans}>
          
        </li>)}
        <li className={ content[4] == true ? `${styles.Que} ${styles.active}` : `${styles.Que}`} onClick={()=>{handleClick(4)}}>
          
        </li>
        { content[4] && (<li className={styles.Ans}>
          
        </li>)} */}
      </ul>
    </div>       
    </>
  )
}
const Privacy = () => {
  
const [content, setContent] = useState([false,false,false,false,false]);

const handleClick = (index: any) => {
  setContent(prevContent => {
    const newContent = [...prevContent];
    newContent[index] = !newContent[index];
    return newContent;
  });
};

  return (
    <>
    <div className={styles.All}>
      <ul>
        <li className={ content[0] == true ? `${styles.Que} ${styles.active}` : `${styles.Que}`} onClick={()=>{handleClick(0)}}>
          DTC 유전자 검사 시 개인정보 관리는 어떻게 되나요?
        </li>
        { content[0] && (<li className={styles.Ans}>
          개인정보는 검사 목적에 필요한 최소한의 정보만을 요구하고, 수집된 정보는 목적 외에 사용하지 않으며, 개인식별 정보와 개인정보 보호 정책에 따라 분실·유출 및 훼손되지 않도록 철저하고 안전하게 관리하고 있습니다.
        </li>)}
        <li className={ content[1] == true ? `${styles.Que} ${styles.active}` : `${styles.Que}`} onClick={()=>{handleClick(1)}}>
          한컴GX의 개인정보 취급 방침에 대해 알려주세요.
        </li>
        { content[1] && (<li className={styles.Ans}>
          1. 본 검사의 결과에 포함된 유전정보는 개인정보보호법상 건강에 관한 정보로서 민감정보에 해당합니다. <br/>
          <br/>
          2. 본 검사의 결과는 검사대상자 본인 또는 법정대리인에게 직접 제공하는 것을 원칙으로 하며 본인 이외의 자가 결과지에 포함된 정보의 전부 또는 일부를 제 3자에게 공개, 배포, 복사하는 등의 행위는 엄격히 금지되고 있습니다. 따라서, 본 검사 결과가 본인의 것이 아닐 경우, 검사기관에 알려주시고 반드시 반송하시기 바랍니다.<br/>
          <br/>
          3. 본 유전자검사 결과는 생명윤리법에 따라 유전정보에 의한 교육·고용·승진·등 사회활동에 있어서 차별의 근거로 사용할 수 없습니다.<br/>
          <br/>
          4. 본 유전자검사 관련 문서, 기록 및 유전자검사 결과는 생명윤리 및 안전에 관한 법률 및 본 기관의 내부 문서·기록 관리 규정에 따라 보관·관리됩니다. <br/><br/>
            <span style={{marginLeft:"16px",color: "red"}}>----- 최종 구현 웹내 한컴케어링크의 개인정보 처리방침 URL 삽입 영역 -----  </span><br/><br/>
          <div style={{marginLeft:"16px"}}>4.1. 기술적 조치<br/>
          료의 보관 및 분석 시 사용하는 컴퓨터는 정보유출방지 프로그램 또는 방화벽과 백신프로그램을 설치하고, 관련 시스템과 자료는 암호를 설정하도록 하며, 개인정보가 포함된 자료는 권한 있는 개인정보취급자만이 개인정보시스템에 접근할 수 있습니다.<br/>
          4.2. 관리적 조치<br/>
          개인정보가 포함된 자료는 개인별 보안지침을 준수하도록 보안의식교육을 이수한 담당자가 지정되어 관리합니다. <br/>
          4.3. 물리적 조치<br/>
          자료의 보관은 외부인의 침입을 확인할 수 있는 CCTV를 설치하고 시건장치를 설치한 별도의 장소(전산실, 자료보관실 등 개인정보를 보관하고 있는 물리적 보관 장소)에 보관합니다.<br/></div>
          <br/>
          5. 본 유전자검사 서비스를 위해 수집된 개인정보는 본 기관 개인정보보호처리방침에 따라 기록·보관·관리됩니다. <br/>
          <div style={{marginLeft:"16px"}}>5.1. 보관하는 정보: 유전자 검사 동의서, 결과 및 검체에 관한 기록 | 보존근거: 생명윤리 및 안전에 관한 법률 |  보존기간: 10년 <br/>
          5.2. 보관하는 정보: 계약 또는 청약철회 등에 관한 기록 | 보존근거: 전자상거래 등에서의 소비자보호에 관한 법률 | 보존기간: 5년 <br/>
          5.3. 보관하는 정보: 소비자의 불만 또는 분쟁처리에 관한 기록 | 보존근거: 전자상거래 등에서 소비자보호에 관한 법률 | 보존기간: 3년<br/>
          5.4. 보관하는 정보: 본인 확인에 관한 기록 | 보존근거: 정보통신망 이용촉진 및 정보보호 등에 관한 법률 | 보존기간: 6개월<br/>
          5.5. 보관하는 정보: 웹사이트 방문에 관한 기록 | 보존근거: 통신비밀보호법 | 보존기간: 3개월<br/></div>
          <br/>
          6. 본 검사기관은 개인정보보호와 관련하여 이용자의 의견을 수렴하고 불만을 처리하기 위해 고객상담센터를 운영하고 있습니다.<br/>
          고객센터 전화: 1800-5270<br/>
          운영시간: 평일 오전 10:00 - 오후 5:00.

        </li>)}
        {/* <li className={ content[2] == true ? `${styles.Que} ${styles.active}` : `${styles.Que}`} onClick={()=>{handleClick(2)}}>
          
        </li>
        { content[2] && (<li className={styles.Ans}>
          
        </li>)}
        <li className={ content[3] == true ? `${styles.Que} ${styles.active}` : `${styles.Que}`} onClick={()=>{handleClick(3)}}>
          
        </li>
        { content[3] && (<li className={styles.Ans}>
          
        </li>)}
        <li className={ content[4] == true ? `${styles.Que} ${styles.active}` : `${styles.Que}`} onClick={()=>{handleClick(4)}}>
          
        </li>
        { content[4] && (<li className={styles.Ans}>
          
        </li>)} */}
      </ul>
    </div>       
    </>
  )
}


function FAQ() {
  
  //tab
  let [tab, setTab] = useState(0);

  return (
    <>
      <div>
        {/* header */}
        <Header/>

        <section className={`${styles.section} ${styles.FAQ}`}>
          <div className={styles.inner}>
            <h3>자주 묻는 질문</h3>
            
            <div className={styles.tab_box}>
              <div onClick={() => { setTab(0) }} className={ tab == 0 ? `${styles.active}` : ''}>서비스</div>
              <div onClick={() => { setTab(1) }} className={ tab == 1 ? `${styles.active}` : ''}>구매</div>
              <div onClick={() => { setTab(2) }} className={ tab == 2 ? `${styles.active}` : ''}>결과</div>
              <div onClick={() => { setTab(3) }} className={ tab == 3 ? `${styles.active}` : ''}>개인정보</div>
            </div>

            {/* 1 */}
            { tab == 0 ? <Service/> : null }
            {/* 2 */}
            { tab == 1 ? <Purchase/> : null }
            {/* 3 */}
            { tab == 2 ? <Result/> : null }
            {/* 4 */}
            { tab == 3 ? <Privacy/> : null }

          </div>
        </section>

        {/* footer */}
        <Footer/>
        
      </div>
    </>
  );
}

export default FAQ;
