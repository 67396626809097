/* eslint-disable */
import React, { useState, useEffect, useRef, CSSProperties, HTMLAttributes, ReactNode } from "react";
import { Link, NavLink, Outlet, useMatch } from "react-router-dom";
import { Properties } from 'csstype';

//img
import m_menu from "../img/icn/m_menu.svg";
import m_menu_close from "../img/icn/m_menu_close.svg";

// css
import styles from "../css/common.module.css";

function Header () {

  const [subMenu_back, setSubMenu_back] = useState("-225px");

  const openSubMenu_back = () => {
    setSubMenu_back("87px");
  }
  const closeSubMenu_back = () => {
    setSubMenu_back("-225px");
  }

  const sub1 = useMatch("/sub1");
  const sub2 = useMatch("/sub2");
  const sub3 = useMatch("/sub3");
  const sub4 = useMatch("/sub4");
  const Notice = useMatch("/Notice");
  const FAQ = useMatch("/FAQ");
  const Inquiry = useMatch("/Inquiry");

  // mobile
  const [menu_box_m, setmenu_box_m] = useState(false);
  const openMenu_box_m = () => {
    setmenu_box_m(!menu_box_m);
  }
  

  const [subMenus, setSubMenus] = useState({ m1: false, m2: false, m3: false });
  const [subMenus_active, setSubMenus_active] = useState({ m1: false, m2: false, m3: false });
  const openSubMenu = (menu: 'm1' | 'm2' | 'm3') => {
    setSubMenus((prev) => ({ ...prev, [menu]: !prev[menu] }));
    setSubMenus_active((prev) => ({ ...prev, [menu]: !prev[menu] }));
  };
  

  type StyleProps = Properties<string | number, string & {}> & {
    visibility: 'visible' | 'hidden' | 'collapse' | 'initial' | 'inherit';
  };
  const subMenuStyleTure: StyleProps = {
      height: "auto",
      visibility: "visible",
      opacity: "1",
      margin: "8px 0 40px",
  }
  const subMenuStyleFalse: StyleProps = {
    height: "0px",
    visibility: "hidden",
    opacity: "0",
    margin: "0"
  }
  
    return (
        <>
        <header className={styles.header}>
          <div className={styles.inner}>
            <h1 className={styles.logo} onClick={() => {window.location.reload();}}><Link to="/"></Link></h1>
            <ul onMouseOver={openSubMenu_back} onMouseLeave={closeSubMenu_back}>
              <li>
                <NavLink 
                  to=""
                  className={sub1 || sub2 || sub3 ? `${styles.active}` : ''} 
                >GX SERVICE</NavLink>
                <ol>
                  <li><Link to="/sub1">한컴GX 소개</Link></li>
                  <li><Link to="/sub2">유전체 분석 제품</Link></li>
                  <li><Link to="/sub3">검사기관 위치</Link></li>
                </ol>
              </li>
              <li>
                <NavLink 
                  to=""
                  className={sub4 ? `${styles.active}` : ''}
                >ORDER NOW</NavLink>
                <ol>
                  <li><Link to="/sub4">구매하기</Link></li>
                </ol>
              </li>
              <li>
                <NavLink 
                  to=""
                  className={Notice || FAQ || Inquiry ? `${styles.active}` : ''} 
                >CONTACT</NavLink>
                <ol>
                  <li><Link to="/Notice">공지사항</Link></li>
                  <li><Link to="/FAQ">자주묻는질문</Link></li>
                  <li><Link to="/Inquiry">문의하기</Link></li>
                </ol>
              </li>
            </ul>
          </div>
        </header>
        <div className={styles.subMenu_back} style={{top: `${subMenu_back}`}}></div> 
        
        <header className={styles.header_m}>
          <div>
            <h1 className={styles.logo_m} onClick={() => {window.location.reload();}}><Link to="/"></Link></h1>
            <p 
              className={styles.menu_m} 
              onClick={openMenu_box_m}
              style={ menu_box_m == true ? {background: "url("+ m_menu_close +")"} : {background: "url("+ m_menu +")"}}
              >
            </p>
          </div>
        </header>
        <div className={styles.menu_box_m} style={ menu_box_m == true ? {top: "56px", opacity:"1"} : {top: "-100vh", opacity:"0"} }>
          <ul>
            <li>
              <p
                onClick={()=>{openSubMenu('m1')}}
                className={ subMenus_active.m1 == true ? `${styles.active}` : ""}
                >GX SERVICE</p>
              <ol
                style={ subMenus.m1 == true ? subMenuStyleTure : subMenuStyleFalse}>
                <li><Link to="/sub1">한컴GX 소개</Link></li>
                <li><Link to="/sub2">유전체 분석 제품</Link></li>
                <li><Link to="/sub3">검사기관 위치</Link></li>
              </ol>
            </li>
            <li>
              <p
                onClick={()=>{openSubMenu('m2')}}
                className={ subMenus_active.m2 == true ? `${styles.active}` : ""}
              >ORDER NOW</p>
              <ol
                style={ subMenus.m2 == true ? subMenuStyleTure : subMenuStyleFalse}>
                <li><Link to="/sub4">구매하기</Link></li>
              </ol>
            </li>
            <li>
              <p
                onClick={()=>{openSubMenu('m3')}}
                className={ subMenus_active.m3 == true ? `${styles.active}` : ""}
              >CONTACT</p>
              <ol
                style={ subMenus.m3 == true ? subMenuStyleTure : subMenuStyleFalse}>
                <li><Link to="/Notice">공지사항</Link></li>
                <li><Link to="/FAQ">자주묻는질문</Link></li>
                <li><Link to="/Inquiry">문의하기</Link></li>
              </ol>
            </li>
          </ul>
          {/* <p className={styles.copyright_m}>COPYRIGHT ⓒ 2023 HANCOM CARELINK Inc. All Rights Reserved</p> */}
        </div>
        </>
    )
}

export default Header;
