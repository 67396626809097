/* eslint-disable */
import React, { Children, useRef, useEffect, useCallback, LiHTMLAttributes, RefObject } from "react";
import { useState } from 'react';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import styled from "styled-components";
// @ts-ignore
import { Flippy, FlippyProps, FrontSide, BackSide } from 'react-flippy';

// css
import styles from "./css/main.module.css";

//import component
import Header from "./common/Header";
import Footer from "./common/Footer";

//img
import bg1 from "./img/icn/img_main_bg1.svg";
import bg2 from "./img/icn/img_main_bg2.svg";


import UseScrollFade_left from "./common/UseScrollFade/UseScrollFade_left";
import UseScrollFade_top from "./common/UseScrollFade/UseScrollFade_top";


function Main() {

  const flippyRef = useRef<FlippyProps>(null);

  //fade
  const fade = Array.from({ length: 17 }, () => UseScrollFade_top());
  const fade_left = Array.from({ length: 2 }, () => UseScrollFade_left());

  type LiProps = LiHTMLAttributes<HTMLLIElement>;
  type Divprops = RefObject<HTMLDivElement>;


  return (
    <>
      <div className={styles.wrap}>
        {/* header */}
        <Header/>

        <section className={`${styles.section} ${styles.section1}`}>
          <div className={styles.inner} {...fade[1]}>
            <h1><span>더 나은 내일을 위한 계획</span><br/>한컴 GX로 시작해보세요.</h1>
            <p>나의 유전적 특징을 알아보고 <br className={styles.m_view}/>더 나은 내일을 설계할 수 있어요!</p>
      
            <img src={bg1} alt="" className={styles.bg1}/>

            <div className={styles.btn}>
              <button className={styles.active_btn} type="button"><Link to="/sub4">지금바로 주문하기</Link></button>
              <button className={styles.defalut_btn} type="button"><Link to="/sub1">더 알아보기</Link></button>
            </div>

          </div>
        </section>

        <section className={`${styles.section} ${styles.section2}`}>
          <div className={styles.inner} {...fade[2]}>

            <h3>Genetic Characteristics</h3>
            <h1>유전자는 나에 대한<br/>흥미로운 스토리를 들려줄 거에요.</h1>
            <p>나를 만드는 다양한 유전적 특징과 <br className={styles.m_view}/>유전적 경향을 발견하세요!</p>
            <img src={bg2} alt="" className={styles.bg2}/>
          </div>

        </section>

        <section className={`${styles.section} ${styles.section3}`}>
          <div className={styles.inner}>
            <h3>PRODUCT</h3>
            <h1>한컴 GX의 다양한<br/>개인유전체 분석 상품을 만나보세요!</h1>

            <div className={styles.filppy_wrap}>
              <div {...fade[3]}>
              <Flippy
                className={styles.filppy}
                flipOnHover={false}
                flipOnClick={true}
                flipDirection="horizontal"
                isFlipped={false} // isFlipped prop 전달
                innerref={flippyRef} // innerRef prop으로 ref 선언
              >
                <FrontSide
                  className={`${styles.frontSide} ${styles.frontSide1}`}
                >
                  <h4 style={{ 
                    color: '#219A60',
                  }}>GREEN</h4>
                  <h5>영양소</h5>
                  <div></div>
                </FrontSide>
                <BackSide
                  className={styles.backSide}
                  style={{ 
                    backgroundColor: '#ECFFF0',
                  }}
                >
                  <h4 style={{ 
                    color: '#219A60',
                  }}>GREEN</h4>
                  <p>
                  한컴 GX Green 패키지는<br/>
                  인체의 비타민 & 미네랄의<br/>
                  농도와 관련된 유전체 분석을<br/>진행 합니다.
                    <span>비타민B 농도, 비타민D<br/>
                    농도, 마그네슘 농도, 칼슘 농도 등<br/>19개 항목
                    </span>
                  </p>
                </BackSide>
              </Flippy>
              </div>
              <div {...fade[4]}>
              <Flippy
                className={styles.filppy}
                flipOnHover={false}
                flipOnClick={true}
                flipDirection="horizontal"
                isFlipped={false} // isFlipped prop 전달
                innerref={flippyRef} // innerRef prop으로 ref 선언
              >
                <FrontSide
                  className={`${styles.frontSide} ${styles.frontSide2}`}
                >
                  <h4 style={{ 
                    color: '#F8463B',
                  }}>RED</h4>
                  <h5>운동</h5>
                  <div></div>
                </FrontSide>
                <BackSide
                  className={styles.backSide}
                  style={{ 
                    backgroundColor: '#FFE8E8',
                  }}
                >
                  <h4 style={{ 
                    color: '#F8463B',
                  }}>RED</h4>
                  <p>
                  한컴 GX Red 패키지는<br/>
                  인체의 운동능력 & 운동효과와<br/>
                  관련된 유전체 분석을<br/>
                  진행 합니다.
                    <span>근력 운동 적합성, 근육발달능력,<br/>
                    발목 부상 위험도, 운동 후 회복능력 등<br/>
                    10개 항목
                    </span>
                  </p>
                </BackSide>
              </Flippy>
              </div>
              <div {...fade[5]}>
              <Flippy
                className={styles.filppy}
                flipOnHover={false}
                flipOnClick={true}
                flipDirection="horizontal"
                isFlipped={false} // isFlipped prop 전달
                innerref={flippyRef} // innerRef prop으로 ref 선언
              >
                <FrontSide
                  className={`${styles.frontSide} ${styles.frontSide3}`}
                >
                  <h4 style={{ 
                    color: '#0C73BE',
                  }}>BLUE</h4>
                  <h5>건강관리</h5>
                  <div></div>
                </FrontSide>
                <BackSide
                  className={styles.backSide}
                  style={{  
                    backgroundColor: '#E2EEFF',
                  }}
                >
                <h4 style={{ 
                  color: '#0C73BE',
                }}>BLUE</h4>
                <p>
                한컴 GX Blue 패키지는<br/>
                인체의 신체건강과 관련된<br/>
                유전체 분석을 진행 합니다.
                  <span>
                  체질량지수, 중성지방농도, 혈압,<br/>
                  요산치, 콜레스테롤 등<br/>
                  10개 항목
                  </span>
                </p>
                </BackSide>
              </Flippy>
              </div>
              <div {...fade[6]}>
              <Flippy
                className={styles.filppy}
                flipOnHover={false}
                flipOnClick={true}
                flipDirection="horizontal"
                isFlipped={false} // isFlipped prop 전달
                innerref={flippyRef} // innerRef prop으로 ref 선언
              >
                <FrontSide
                  className={`${styles.frontSide} ${styles.frontSide4}`}
                >
                  <h4 style={{ 
                    color: '#E723BB',
                  }}>PINK</h4>
                  <h5>피부/모발</h5>
                  <div></div>
                </FrontSide>
                <BackSide
                  className={styles.backSide}
                  style={{ 
                    backgroundColor: '#FFF0FF',
                  }}
                >
                <h4 style={{ 
                  color: '#E723BB',
                }}>PINK</h4>
                <p>
                한컴 GX Pink 패키지는<br/>
                인체의 피부 & 모발과 관련된<br/>
                유전체 분석을 진행 합니다.
                  <span>
                  색소침착, 피부노화, 알코올 홍조,<br/>
                  남성형 탈모, 원형 탈모, 모발 굵기 등<br/>
                  12개 항목
                  </span>
                </p>
                </BackSide>
              </Flippy>
              </div>
              <div {...fade[7]}>
              <Flippy
                className={styles.filppy}
                flipOnHover={false}
                flipOnClick={true}
                flipDirection="horizontal"
                isFlipped={false} // isFlipped prop 전달
                innerref={flippyRef} // innerRef prop으로 ref 선언
              >
                <FrontSide
                  className={`${styles.frontSide} ${styles.frontSide5}`}
                >
                  <h4 style={{ 
                    color: '#EC5805',
                  }}>ORANGE</h4>
                  <h5>개인특성</h5>
                  <div></div>
                </FrontSide>
                <BackSide
                  className={styles.backSide}
                  style={{ 
                    backgroundColor: '#FFF9F0',
                  }}
                >
                <h4 style={{ 
                  color: '#EC5805',
                }}>ORANGE</h4>
                <p>
                한컴 GX Orange 패키지는<br/>
                인체의 식습관 & 개인특성 등과<br/>
                관련된 유전체 분석을<br/>
                진행 합니다.
                  <span>
                  단맛 민감도, 카페인 대사, 알코올<br/>
                  의존성, 수면습관, 통증 민감성 등<br/>
                  18개 항목 
                  </span>
                </p>
                </BackSide>
              </Flippy>
              </div>
            </div>
            <div className={styles.btn_box}>
              <button type="button" className={styles.defalut_btn}><Link to="/sub2">더 알아보기</Link></button>
            </div>
          </div>
        </section>

        <section className={`${styles.section} ${styles.section4}`}>
          <div className={styles.inner}>
            <h3>PROCESS</h3>
            <h1>한컴 GX는 이렇게 이용할 수 있어요.</h1>

            <div className={styles.process_box}>
              <div {...fade[8]}>
                <h5>SETP1</h5>
                <dl>
                  <dt>유전체 분석 의뢰</dt>
                  <dd>의료기관(병·의원 및 검진센터)과<br/>
                  DTC(개인 유전체 분석) 상품 구매를 통해<br/>
                  유전자 분석의뢰를 신청</dd>
                </dl>
              </div>
              <div {...fade[9]}>
                <h5>SETP2</h5>
                <dl>
                  <dt>검체 채취</dt>
                  <dd>혈액, 타액, 구강 상피세포 등을 채취하여<br/>
                  유전체 분석을 위한 검체 채취 </dd>
                </dl>
              </div>
              <div {...fade[10]}>
                <h5>SETP3</h5>
                <dl>
                  <dt>분석결과 확인</dt>
                  <dd>한눈에 들어오는 분석결과 리포트에서<br/>
                  데이터로 DNA 정보를 확인</dd>
                </dl>
              </div>
              <div {...fade[11]}>
                <h5>SETP4</h5>
                <dl>
                  <dt>맞춤형 건강정보 받아보기</dt>
                  <dd>나의 유전체 분석 결과 리포트 정보를<br/>
                  토대로 맞춤형 건강정보 받아보기</dd>
                </dl>
              </div>

            </div>
          </div>
        </section>

        <section className={`${styles.section} ${styles.section5}`}>
          <div className={styles.inner}>
            <h1>왜 <span>한컴GX</span> 일까요?</h1>
            <div {...fade_left[1]}></div> 
            
            <div className={styles.whyCarelink}>
              <ul>
                <li className={styles.clearfix} {...fade[12] as LiProps}>
                  <div>
                    <dl>
                      <dt>패키지형 유전체 분석 제품</dt>
                      <dd>고객들의 만족도가 높은 유전체 분석 항목들을 패키지화 하여 유사한 유전체 분석을 분야의 분석 결과를 한번에 받아볼 수 있어요!</dd>
                    </dl>
                  </div>
                </li>
                <li className={styles.clearfix} {...fade[13] as LiProps}>
                  <div>
                    <dl>
                      <dt>쉽고 간편한 분석의뢰 및 확인 프로세스</dt>
                      <dd>사용이 간편한 검체 채취 키트와 온라인을 통한 분석결과 확인으로 유전체 분석이 더욱 편리해요!</dd>
                    </dl>
                  </div>
                </li>
                <li className={styles.clearfix} {...fade[14] as LiProps}>
                  <div>
                    <dl>
                      <dt>개인 맞춤형 유전체 분석 제품</dt>
                      <dd>고객이 유전체 분석을 원하는 유전체 분석 항목만 선택하여 개인 맞춤형 유전체 분석 제품을 구성하여 의뢰할 수 있어요!</dd>
                    </dl>
                  </div>
                </li>
                <li className={styles.clearfix} {...fade[15] as LiProps}>
                  <div>
                    <dl>
                      <dt>누구나 알아보기 쉬운 분석결과 리포트</dt>
                      <dd>직관적인 유전체 분석결과 리포트의 화면 구성과 유전체 분석 결과 내용을 뒷받침하는 설명으로 이해하기 쉬워요!</dd>
                    </dl>
                  </div>
                </li>
              </ul>
            </div>
            
            <div className={styles.FAQ_box} {...fade[16]}>
              <p>궁금한 사항이 있으시면 언제든 물어보세요!</p>
              <p>한컴케어링크 담당자가 성의껏 답변 드리겠습니다.</p>

              <div className={styles.btn}>
                <button type="button" className={styles.defalut_btn}><Link to="/FAQ">FAQ</Link></button>
                <button type="button" className={styles.defalut_btn}><Link to="/Inquiry">상담문의</Link></button>
              </div>

            </div>


          </div>
        </section>        

        {/* footer */}
        <Footer/>
      </div>
    </>
  );
}

export default Main;
