/* eslint-disable */
import React, { useRef } from "react";
import styled from "styled-components";

// css
import styles from "../css/sub2.module.css";


//slick
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const StyledSlider = styled(Slider)`

  .slick-slide {
    li {
      width: calc(100% - 16px) !important;
    }
  }
  @media only screen and (max-width : 768px) {
    .slick-slide {
      li {
        width: calc(100% - 8px) !important;
      }
    }
  }

`;
const StyledSlider2 = styled(Slider)`


`;

function Sub2_1() {

  const settings = {
    arrows: false,
    autoplay: false,
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    draggable : false,
    touchMove: false,

    responsive: [
      {
        breakpoint: 1040,
        settings: {
          slidesToShow: 5.2,
          slidesToScroll: 2,
          draggable : true,
          touchMove: true,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3.4,
          slidesToScroll: 2,
          draggable : true,
          touchMove: true,
        }
      },
    ]
  };

  const settings2 = {
    arrows: false,
    autoplay: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    draggable : false,
    touchMove: false,

    responsive: [
      {
        breakpoint: 1040,
        settings: {
          slidesToShow: 3.6,
          slidesToScroll: 1,
          draggable : true,
          touchMove: true,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.4,
          slidesToScroll: 1,
          draggable : true,
          touchMove: true,
        }
      },
    ]
  };

  const content1 = useRef<HTMLDivElement>(null);
  const content2 = useRef<HTMLDivElement>(null);
  const content3 = useRef<HTMLDivElement>(null);
  const content4 = useRef<HTMLDivElement>(null);
  const content5 = useRef<HTMLDivElement>(null);
  const content6 = useRef<HTMLDivElement>(null);

  const scrollToRef1 = () => {
    content1.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  const scrollToRef2 = () => {
    content2.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  const scrollToRef3 = () => {
    content3.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  const scrollToRef4 = () => {
    content4.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  const scrollToRef5 = () => {
    content5.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  const scrollToRef6 = () => {
    content6.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };


  return (
    <>
      <div>
        <div className={styles.sub2_1_wrap}>
          <p className={styles.txt1}>한컴GX의 다양한 유전체 분석 제품 <br className={styles.m_view}/>라인업을 만나보세요!</p>

          <div className={styles.colorTab}>
            <ul>
              <StyledSlider {...settings}>
                <li onClick={scrollToRef1}>전체</li>
                <li onClick={scrollToRef1}>Purple</li>
                <li onClick={scrollToRef2}>Green</li>
                <li onClick={scrollToRef3}>Red</li>
                <li onClick={scrollToRef4}>Blue</li>
                <li onClick={scrollToRef5}>Pink</li>
                <li onClick={scrollToRef6}>Orange</li>
              </StyledSlider>
            </ul>
          </div>
        </div>

        <div className={styles.content_wrap}>        
          <div className={`${styles.content} ${styles.content1}`} ref={content1}>
            <h4>Purple</h4>
            <div className={styles.box1}>
              <dl>
                <dt>DTC 풀 패키지</dt>
                <dd>한컴 GX Purple 패키지는 한컴 GX에서 진행하는 DTC항목 전체에 대해 유전체 분석을 진행하는 풀 패키지 제품입니다. 영양, 운동, 건강, 스킨, 개인특성과 관련된 모든 항목을 한번에 유전체 분석하여 종합적 유전적 결과를 확인할 수 있어요.
                나의 유전체가 말해주는 내 인체의 다양한 스토리를 확인하고 건강한 내일을 계획해 보아요! </dd>
              </dl>
            </div>
            <div className={styles.box2}>
              <h6 className={styles.w_view}>주요 분석 항목</h6>
              <div>
                <ul>
                  <StyledSlider2 {...settings2}>
                    <li className={styles.box2_li_1}>
                      <dl>
                        <dt>영양소</dt>
                        <dd>비타민</dd>
                        <dd>미네랄</dd>
                      </dl>
                    </li>
                    <li className={styles.box2_li_2}>
                      <dl>
                        <dt>운동</dt>
                        <dd>운동능력</dd>
                        <dd>운동효과</dd>
                      </dl>
                    </li>
                    <li className={styles.box2_li_3}>
                      <dl>
                        <dt>건강관리</dt>
                        <dd>신체건강</dd>
                      </dl>
                    </li>
                    <li className={styles.box2_li_4}>
                      <dl>
                        <dt>피부/모발</dt>
                        <dd>피부</dd>
                        <dd>모발</dd>
                      </dl>
                    </li>
                    <li className={styles.box2_li_5}>
                      <dl>
                        <dt>개인특성</dt>
                        <dd>식습관</dd>
                        <dd>개인습관</dd>
                      </dl>
                    </li>
                  </StyledSlider2>
                </ul>
              </div>
            </div>
          </div>        
          <div className={`${styles.content} ${styles.content2}`} ref={content2}>
            <h4>Green</h4>
            <div className={styles.box1}>
              <dl>
                <dt>영양소</dt>
                <dd>한컴 GX Green 패키지는 인체의 비타민 & 미네랄의 농도와 관련된 유전체 분석을 진행 합니다.
                비타민과 미네랄은 인체의 신진대사에서 촉매 역할을 하고 산소와 물을 요구하는 것처럼 생존하고 제대로 기능하는데 
                필요합니다. 나의 유전체가 말해주는 비타민과 미네랄에 대한 스토리를 확인하고 건강한 내일을 계획해 보아요!</dd>
              </dl>
            </div>
            <div className={styles.box2_1}>
              <div>
                <p>비타민</p>
                <ul className={styles.claerfix}>
                  <li>비타민 A 농도 (Vitamin A)</li>
                  <li>비타민 B6 농도 (Vitamin B6)</li>
                  <li>비타민 B12 농도 (Vitamin B12)</li>
                  <li>비타민 C 농도 (Vitamin C)</li>
                  <li>비타민 D 농도 (Vitamin D)</li>
                  <li>비타민 E 농도 (Vitamin E)</li>
                  <li>비타민 K 농도 (Vitamin K)</li>
                </ul>
              </div>
              <div>
                <p>미네랄</p>
                <ul className={styles.claerfix}>
                  <li>코엔자임 Q10 농도 (Coenzyme Q10)</li>
                  <li>마그네슘 농도 (Magnesium)</li>
                  <li>아연 농도 (Zinc)</li>
                  <li>철 저장 및 농도 (Iron)</li>
                  <li>칼륨 농도 (Potassium)</li>
                  <li>아르기닌 농도 (Arginine)</li>
                  <li>지방산 농도 (Fatty Acid)</li>
                  <li>타이로닌 농도 (Tyrosine)</li>
                  <li>베타인 농도 (Betaine)</li>
                  <li>셀레늄 농도 (Selenium)</li>
                  <li>루테인&지아잔틴 농도 (Lutein&Zeaxanthin)</li>
                </ul>
              </div>
            </div>
          </div>        
          <div className={`${styles.content} ${styles.content3}`} ref={content3}>
            <h4>Red</h4>
            <div className={styles.box1}>
              <dl>
                <dt>운동</dt>
                <dd>한컴 GX Red 패키지는 인체의 운동능력 & 운동효과와 관련된 유전체 분석을 진행 합니다. 
                운동은 신체를 구성하는 근육과 근력의 상승을 비롯해 신체기능을 활발하게 하는 등 머리부터 발끝까지 신체의 
                긍정적인 변화를 일으키죠. 나의 유전체가 말해주는 운동능력과 운동효과에 대한 스토리를 확인하고 
                건강한 내일을 계획해 보아요!</dd>
              </dl>
            </div>
            <div className={styles.box2_1}>
              <div>
                <p>운동능력</p>
                <ul className={styles.claerfix}>
                  <li>근력 운동 적합성 (Muscle Strength)</li>
                  <li>유산소 운동 적합성 (Exercise Aerobic Capacity)</li>
                  <li>지구력 운동 적합성 (Endurance Performance)</li>
                  <li>근육발달능력 (Muscle Growth)</li>
                  <li>단거리 질주 능력 (Sprint Ability)</li>
                  <li>악력 (Grip)</li>
                </ul>
              </div>
              <div>
                <p>운동효과</p>
                <ul className={styles.claerfix}>
                  <li>발목 부상 위험도 (Achilles Tendon Injury)</li>
                  <li>운동 후 회복능력 (Post-Exercise Recovery)</li>
                  <li>운동에 의한 체중감량 효과 (Weight Loss)</li>
                  <li>체중감량 후 체중회복가능성 (요요 가능성) (Weight Loss Regain)</li>
                </ul>
              </div>
            </div>
          </div>        
          <div className={`${styles.content} ${styles.content4}`} ref={content4}>
            <h4>Blue</h4>
            <div className={styles.box1}>
              <dl>
                <dt>건강관리</dt>
                <dd>한컴 GX Blue 패키지는 인체의 신체건강과 관련된 유전체 분석을 진행 합니다. 현대인들이 신체건강을
                유지하기 위해 관심이 높은 항목들과 필수적으로 체크하는 항목들을 선별하여 분석을 진행할 수 있어요.
                나의 유전체가 말해주는 신체건강에 대한 스토리를 확인하고 건강한 내일을 계획해 보아요!</dd>
              </dl>
            </div>
            <div className={styles.box2_1}>
              <div>
                <p>신체건강</p>
                <ul className={styles.claerfix}>
                  <li>비만 (Obesity)</li>
                  <li>체질량지수 (Body Mass Index)</li>
                  <li>체지방율 (Body Fat)</li>
                  <li>복부비만(엉덩이 허리 비율) (Waist Hip Ratio)</li>
                  <li>중성지방농도 (Triglyceride)</li>
                  <li>콜레스테롤 (Cholesterol)</li>
                  <li>혈당 (Fasting Blood Sugar)</li>
                  <li>혈압 (Blood Pressure)</li>
                  <li>요산치 (Uric Acid)</li>
                  <li>골질량 (Bone Mass)</li>
                </ul>
              </div>
            </div>
          </div>        
          <div className={`${styles.content} ${styles.content5}`} ref={content5}>
            <h4>Pink</h4>
            <div className={styles.box1}>
              <dl>
                <dt>피부/모발</dt>
                <dd>한컴 GX Pink 패키지는 인체의 피부 & 모발과 관련된 유전체 분석을 진행 합니다. 
                아름다움에 대한 추구는 삶의 질을 윤택하게 하죠. 타고난 나의 피부와 모발은 어떠한 아름다움을 가져다 줄까요? 
                나의 유전체가 말해주는 피부 & 모발에 대한 스토리를 확인하고 건강한 내일을 계획해 보아요!</dd>
              </dl>
            </div>
            <div className={styles.box2_1}>
              <div>
                <p>피부</p>
                <ul className={styles.claerfix}>
                  <li>기미/주근깨 (Freckles)</li>
                  <li>색소침착 (Pigmentation)</li>
                  <li>태양 노출 후 태닝반응 (Tanning)</li>
                  <li>피부노화 (Skin Glycation)</li>
                  <li>여드름 발생 (Acne)</li>
                  <li>피부염증 (Skin Inflammation)</li>
                  <li>튼살 (Striae)</li>
                  <li>알코올 홍조 (Alcohol flush)</li>
                </ul>
              </div>
              <div>
                <p>모발</p>
                <ul className={styles.claerfix}>
                  <li>남성형 탈모 (Hair Loss)</li>
                  <li>원형 탈모 (Alopecia Areat)</li>
                  <li>새치 (White)</li>
                  <li>모발 굵기 (Hair Thickness)</li>
                </ul>
              </div>
            </div>
          </div>        
          <div className={`${styles.content} ${styles.content6}`} ref={content6}>
            <h4>Orange</h4>
            <div className={styles.box1}>
              <dl>
                <dt>개인특성</dt>
                <dd>한컴 GX Orange 패키지는 인체의 식습관 & 개인특성 등과 관련된 유전체 분석을 진행 합니다. 
                개인별로 신체는 다양한 특성들을 가지며 이는 개인의 식습관 및 개인의 특성에 연관이 있죠.
                나의 유전체가 말해주는 식습관 & 개인특성 등에 대한 스토리를 확인하고 건강한 내일을 계획해 보아요!</dd>
              </dl>
            </div>
            <div className={styles.box2_1}>
              <div>
                <p>식습관</p>
                <ul className={styles.claerfix}>
                  <li>단맛 민감도 (Sweet Taste)</li>
                  <li>쓴맛 민감도 (Bitter Taste)</li>
                  <li>짠맛 민감도 (Salty Taste)</li>
                  <li>와인선호도 (Red wine /White wine)</li>
                  <li>식욕 (Appetite)</li>
                  <li>포만감 (Satiety)</li>
                </ul>
              </div>
              <div>
                <p>개인특성</p>
                <ul className={styles.claerfix}>
                  <li>카페인 대사 (Caffeine Metabolism)</li>
                  <li>카페인 의존성 (Caffeine Dependence)</li>
                  <li>알코올 대사 (Alcohol Metabolism)</li>
                  <li>알코올 의존성 (Alcohol Dependence)</li>
                  <li>니코틴 대사 (Nicotine Metabolism)</li>
                  <li>니코틴 의존성 (Nicotine Dependence)</li>
                  <li>불면증 (Insomnia)</li>
                  <li>수면습관 / 시간 (Sleep Duration)</li>
                  <li>아침형 / 저녁형 인간 (Chronotype)</li>
                </ul>
              </div>
              <div>
                <p>기타</p>
                <ul className={styles.claerfix}>
                  <li>퇴행성 관절염증 감수성 (Degenerative Arthritis)</li>
                  <li>통증 민감성 (Pain)</li>
                  <li>멀미 (Motion Sickness)</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sub2_1;
