/* eslint-disable */
import React from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

//rt
import Main from "./Main";
import Sub1 from "./pages/Subpage_1";
import Sub2 from "./pages/Subpage_2";
import Sub3 from "./pages/Subpage_3";
import Sub4 from "./pages/Subpage_4";
import Notice from "./pages/Notice";
import FAQ from "./pages/FAQ";
import Inquiry from "./pages/Inquiry";
import Privacy from "./pages/Privacy";
import ScrollToTop from "./common/ScrollToTop";


function App() {
  return (
    <>
    <BrowserRouter>
    <ScrollToTop/>
      <Routes>
        {/* main */}
        <Route path="/" element={<Main/>}/>

        {/* sub */}
        <Route path="/sub1" element={<Sub1/>}/>
        <Route path="/sub2" element={<Sub2/>}/>
        <Route path="/sub3" element={<Sub3/>}/>
        <Route path="/sub4" element={<Sub4/>}/>
        <Route path="/sub4" element={<Sub4/>}/>
        <Route path="/Notice" element={<Notice/>}/>
        <Route path="/FAQ" element={<FAQ/>}/>
        <Route path="/Inquiry" element={<Inquiry/>}/>
        <Route path="/privacy" element={<Privacy/>}/>
        
      </Routes>
    
    </BrowserRouter>
    </>
  );
}

export default App;
