/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

// css
import styles from "../css/common.module.css";

//img
import box from "../img/icn/pop_box.svg";

const Modal_box = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 30;
  width: 328px;
  overflow: hidden;
  background: #fff url(${box}) no-repeat top 36px center;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  text-align: center;
  padding-top: 112px;

  p {
    font-weight: 600;
    font-size: 16px;
  }

  button {
    margin-top: 24px;
  }

  &::after {
    content:"";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 12px;
    background: #3138D3;
  }

  ${(props:any) => 
    props.preparing && 
    css`
      height: 222px;
    `
  }


`
const Modal_back = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.4);
  z-index: 29;
`

function Modal({children, ...props}: any) {
  return (
    <>
      <Modal_box {...props}>
        {children}
      </Modal_box>
      <Modal_back></Modal_back>

    </>
  );
}

export default Modal;
