/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// css
import styles from "../css/common.module.css";

//img
import naver from "../img/icn/naver.svg";
import youtube from "../img/icn/youtube.svg";
import instagram from "../img/icn/instagram.svg";
import footerLogo from "../img/icn/footer_logo.svg";
import footerLogo_b from "../img/icn/img_gxlogo_black.svg";

function Footer() {

  const naver_url = "https://blog.naver.com/care_link";
  const youtube_url = "https://www.youtube.com/watch?v=ElujBjGNyKQ";
  const instagram_url = "https://www.instagram.com/care_link/";


  const [showButton, setShowButton] = useState(false);

  const scrollToTop = () => {
      window.scroll({
          top: 0,
          behavior: 'smooth'
      })

  }
  useEffect(() => {
      const handleShowButton = () => {
          if (window.scrollY > 100) {
              setShowButton(true)
          } else {
              setShowButton(false)
          }
      }

      window.addEventListener("scroll", handleShowButton)
      return () => {
          window.removeEventListener("scroll", handleShowButton)
      }
  }, []);

  return (
    <>
      <footer className={styles.footer}>
        <div className={styles.inner}>
          <div className={styles.inner_top}>
            <div className={styles.fixed}>
              <dl>
                <dt>한컴케어링크</dt>
                <dd>[13493] 경기도 성남시 분당구 대왕판교로 644번길 49 한컴타워 5층<br/>
                    대표전화: 1800-5270 | FAX: 02-6949-3340</dd>
                <dd>사업자등록번호: 738-86-00322 | <br className={styles.m_view}/>통신판매번호 제 2022-성남분당C-0586호</dd>
              </dl>
            </div>
            <div className={styles.m_view}>
              <dl>
                <dt>HANCOM GX</dt>
                <dd>담당자 연락처: 070-5102-0819 | <br className={styles.m_view}/>E-mail: gxsupport@hancomcl.com</dd>
                <dd className={styles.w_view}>CopyrightⓒHancomcarelink. All rights reserved.</dd>
              </dl>
            </div>
            <div className={`${styles.w_view} ${styles.fixed2}`}>
              <p><Link to="/Privacy">개인정보처리방침</Link></p>
              <p><Link to="/Inquiry">CONTACT</Link></p>
            </div>
            <div>
                <select
                  onChange={(e) => {
                    if (/iPhone|iPad|iPod/.test(navigator.userAgent)) {
                      window.location.href = e.target.value;
                    } else {
                      window.open(e.target.value, "_blank");
                    }
                  }}
                  className={styles.family_site}
                >
                  <option hidden>Family site</option>
                  <option value="http://www.hancom.com">한글과컴퓨터</option>
                  <option value="https://www.hcarelink.com/">케어헬스</option>
                  <option value="http://www.hcarelink.co.kr">한컴케어링크</option>
                </select>
            </div>
            <div className={styles.m_view}>
              <p><Link to="/Privacy">개인정보처리방침</Link></p>
              <p><Link to="/Inquiry">CONTACT</Link></p>
            </div>
          </div>
          <div className={styles.inner_btm}>
            <div className={`${styles.w_view} ${styles.fixed}`} >
              <dl>
                <dt>HANCOM GX</dt>
                <dd>담당자 연락처: 070-5102-0819 | <br className={styles.m_view}/>E-mail: gxsupport@hancomcl.com</dd>
                <dd>CopyrightⓒHancomcarelink. All rights reserved.</dd>
              </dl>
            </div>
            <div className={styles.fixed2}>
              <ul>
                <li><img src={naver} alt="" onClick={()=>{window.open(naver_url)}}/></li>
                <li><img src={youtube} alt="" onClick={()=>{window.open(youtube_url)}}/></li>
                <li><img src={instagram} alt="" onClick={()=>{window.open(instagram_url)}}/></li>
              </ul>
            </div>
            <div>
              <div className={`${styles.clearfix} ${styles.fixed3}`}>
                <img src={footerLogo} alt="" />
                <img className={styles.m_view} src={footerLogo_b} alt="" />
              </div>
              <span className={styles.m_view}>CopyrightⓒHancomcarelink. All rights reserved.</span>
            </div>
          </div>
        </div>


        {/* scrollTop */}
        { showButton && 
          <div 
            onClick={scrollToTop}
            className={styles.scrollTop}
          ></div> 
        }
      </footer>
    </>
  );
}

export default Footer;
