/* eslint-disable */
import { useRef, useCallback, useEffect } from 'react';

const UseScrollFade_left = () => {
  const dom = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(([entry]: IntersectionObserverEntry[]) => {
    const { current } = dom;
      
      if(entry.isIntersecting && current) {
      // 원하는 이벤트를 추가 할 것
      current.style.transitionProperty = 'opacity transform';
      current.style.transitionDuration = '1s';
      current.style.transitionTimingFunction = 'cubic-bezier(0, 0, 0.2, 1)';
      current.style.opacity = '1';
      current.style.transform = 'translateX(0px)';
    }
  }, []);

  useEffect(() => {
    let observer:any;
    const { current } = dom;
    
    if (current) {
      observer = new IntersectionObserver(handleScroll, { threshold: 0.7 });
      observer.observe(current);
      
      return () => observer && observer.disconnect();
    };
  }, [handleScroll]);
  
    return {
    ref: dom,
    style: {
      opacity: 0,
      transform: 'translateX(-40px)',
    }
  };
};

export default UseScrollFade_left;