/* eslint-disable */
import React, { Children } from "react";
import { useState } from 'react';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Modal from "../common/Modal";

// css
import styles from "../css/sub3~etc.module.css";

//import component
import Header from "../common/Header";
import Footer from "../common/Footer";

function Sub4() {
  
  //tab
  let [modal, setModal] = useState(false);

  const modal_openClose = () => {
    setModal(!modal);
  }

  return (
    <>
      <div>
        {/* header */}
        <Header/>
          
        <section className={`${styles.section} ${styles.order}`}>
          <div className={styles.inner}>
            <h3>구매하기</h3>

            <div>
              <p><span className={styles.p1}>한컴GX의</span><br/> 유전체 분석 상품은 <br className={styles.m_view}/><span className={styles.p2}>네이버 스마트스토어</span>에서<br/>간편하게 구매하실 수 있어요!</p>
              <button type="button" className={styles.active_btn} onClick={()=>{modal_openClose()}}><Link to="">구매하러 가기</Link></button>
            </div>

          </div>

          { modal && (
          <Modal preparing>
            <p>상품 준비중입니다.</p>
            <button className={styles.active_btn} onClick={()=>{modal_openClose()}}><Link to="">확인</Link></button>
          </Modal>
          )}

        </section>

        {/* footer */}
        <Footer/>
      </div>
    </>
  );
}

export default Sub4;
